import React, { Dispatch, FC } from 'react';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
import PreviewIcon from '@mui/icons-material/Preview';
import PublishIcon from '@mui/icons-material/Publish';
import useSnackbar from '../../../../../hooks/useSnackbar';
import client from '../../../../../utilities/requestClient';
import TrackedChangesButton from '../../components/buttons/TrackedChangesButton.component';
import { useNavigate } from 'react-router-dom';
import { greys, mainColors } from '../../../../../styling/theme';
import PublishDocumentDialogBox from '../../components/buttons/PublishDocumentDialogBox.component';
import ViewSpecificVersionButton from '../../components/buttons/ViewSpecificVersionButton.component';
import { Tooltip } from '@mui/material';
import { KidsOverviewSecondLevelTableData } from './KidsOverview';
import { useRaidrKids } from '../context/RaidrKidsContext';

const useStyles = makeStyles(() => ({
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

interface KidsOverviewAllFunctionalitySecondLevelTableProps {
  data: KidsOverviewSecondLevelTableData[];
}

interface VersionNumberDisplayProps {
  version: string;
}
// Create a component for displaying the version number with color coding
const VersionNumberDisplay: FC<VersionNumberDisplayProps> = ({
  version,
}: VersionNumberDisplayProps) => {
  // Split the version number into major, minor and patch
  const versionNumbers = version.split('.');
  const major = parseInt(versionNumbers[0]);
  const minor = parseInt(versionNumbers[1]);
  const patch = parseInt(versionNumbers[2]);
  // Decide what color to be used based on the version number
  let color = mainColors.Pass_darker;
  // If Edits have been made then display Amber
  if (patch > 0) color = mainColors.accentOrange;
  // If a review is available then display blue
  if (minor > 0) color = mainColors.mainBlue_lighter;
  // Otherwise the document is published and we leave it green.
  return (
    <div
      style={{
        backgroundColor: color,
        color: 'white',
        borderRadius: '0.5rem',
        padding: '0.2rem',
        // width: '5rem',
        fontWeight: 400,
        // textAlign: 'center',
      }}
    >
      {version}
    </div>
  );
};

function buildTableColumns(
  setDialogIsShowing: Dispatch<boolean>,
  setPublishingDocument: Dispatch<{
    fundId: string;
    shareClass: string;
    documentLanguage: string;
    fundName: string;
  }>,
): CustomColumn<KidsOverviewSecondLevelTableData>[] {
  const navigate = useNavigate();
  const classes = useStyles();
  const { isPriips } = useRaidrKids();
  const detailColumns: CustomColumn<KidsOverviewSecondLevelTableData>[] = [
    {
      title: 'Share Class',
      field: 'shareClassName',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      width: '10%',
    },
    {
      title: 'ISIN',
      field: 'isin',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Version',
      field: 'version',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: KidsOverviewSecondLevelTableData) => (
        <VersionNumberDisplay version={rowData.version} />
      ),
    },
    {
      title: 'Version Status',
      field: 'versionStatus',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      width: '20%',
    },
    {
      title: 'Publication Timestamp',
      field: 'publicationDate',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: KidsOverviewSecondLevelTableData) =>
        rowData.publicationDate.substring(0, 16).replace('T', ' '),
    },
    {
      title: 'Last Edited Timestamp',
      field: 'lasteEditedDate',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: KidsOverviewSecondLevelTableData) =>
        rowData.lasteEditedDate.substring(0, 16).replace('T', ' '),
    },
    {
      title: 'Last Edited By',
      field: 'lastEditedBy',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Edit',
      field: 'edit',
      render: (rowData: KidsOverviewSecondLevelTableData) => (
        <Tooltip
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
          title="Edit Document"
          placement={'top'}
          arrow
          enterNextDelay={1000}
          enterDelay={1000}
        >
          <EditIcon
            sx={{
              fontSize: '3rem',
              color: 'white',
              backgroundColor: mainColors.mainBlue,
              borderRadius: 1,
              padding: '0.2rem',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: mainColors.mainBlue_lighter,
              },
            }}
            onClick={() => {
              navigate(
                isPriips
                  ? `/raidr/priips-kids-editor?fundId=${rowData.fundId}&isin=${rowData.isin}&documentLanguage=${rowData.documentLanguage}`
                  : `/raidr/ucits-kiids-editor?fundId=${rowData.fundId}&isin=${rowData.isin}&documentLanguage=${rowData.documentLanguage}`,
              );
            }}
          />
        </Tooltip>
      ),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      width: '5%',
    },

    {
      title: 'Compare Versions',
      field: '',
      render: (rowData: KidsOverviewSecondLevelTableData) => (
        <TrackedChangesButton
          fundId={rowData.fundId}
          shareClass={rowData.isin}
          documentLanguage={rowData.documentLanguage}
          versionNumbers={rowData.versionNumbers}
          initialComparisonVersion={`${rowData.version.split('.')[0]}.0.0`}
          initialTargetVersion={rowData.version}
        />
      ),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'View Version',
      field: '',
      render: (rowData: KidsOverviewSecondLevelTableData) => (
        <ViewSpecificVersionButton
          fundId={rowData.fundId}
          shareClass={rowData.isin}
          documentLanguage={rowData.documentLanguage}
          versionNumbers={rowData.versionNumbers}
          latestVersionNumber={rowData.version}
        />
      ),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
  ];

  isPriips &&
    detailColumns.push(
      {
        title: 'Review',
        field: '',
        render: (rowData: KidsOverviewSecondLevelTableData) => {
          const isDisabled = rowData.version.split('.')[2] === '0';

          return (
            <Tooltip
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
              title={
                isDisabled
                  ? 'There are currently no changes for review'
                  : 'Click here to review latest changes against the most recent published version'
              }
              placement={'top'}
              arrow
            >
              <PreviewIcon
                sx={{
                  fontSize: '3rem',
                  color: 'white',
                  backgroundColor: isDisabled
                    ? greys.grey400
                    : mainColors.mainBlue,
                  borderRadius: 1,
                  padding: '0.2rem',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor:
                      rowData.version.split('.')[2] === '0'
                        ? greys.grey400
                        : mainColors.mainBlue_lighter,
                  },
                }}
                onClick={() => {
                  if (!isDisabled) {
                    const root = isPriips
                      ? 'priips-kids-review'
                      : 'ucits-kids-review';

                    navigate(
                      `/raidr/${root}?fundId=${rowData.fundId}&isin=${
                        rowData.isin
                      }&documentLanguage=${
                        rowData.documentLanguage
                      }&currentVersion=${rowData.version}&publishedVersion=${
                        rowData.version.split('.')[0] + '.0.0'
                      }`,
                    );
                  }
                }}
              />
            </Tooltip>
          );
        },
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
        width: '5%',
      },
      {
        title: 'Publish Changes',
        field: '',
        render: (rowData: KidsOverviewSecondLevelTableData) => {
          const isDisabled =
            rowData.version.split('.')[1] === '0' ||
            (rowData.version.split('.')[2] === '0' &&
              rowData.version.split('.')[1] === '0');
          return (
            <Tooltip
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
              title={
                isDisabled
                  ? 'Review required before document can be published'
                  : 'Click here to Publish the latest version of the document.'
              }
              placement={'top'}
              arrow
            >
              <PublishIcon
                sx={{
                  fontSize: '3rem',
                  color: 'white',
                  backgroundColor: isDisabled
                    ? greys.grey400
                    : mainColors.mainBlue,
                  borderRadius: 1,
                  padding: '0.2rem',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: isDisabled
                      ? greys.grey400
                      : mainColors.mainBlue,
                  },
                }}
                onClick={() => {
                  if (!isDisabled) {
                    setDialogIsShowing(true);
                    setPublishingDocument({
                      fundId: rowData.fundId,
                      shareClass: rowData.isin,
                      documentLanguage: rowData.documentLanguage,
                      fundName: rowData.fundName,
                    });
                  }
                }}
              />
            </Tooltip>
          );
        },
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: { textAlign: 'center' },
        width: '10%',
      },
    );
  return detailColumns;
}

const KidsOverviewAllFunctionalitySecondLevelTable: FC<
  KidsOverviewAllFunctionalitySecondLevelTableProps
> = (props) => {
  const { data } = props;
  // Create a state object for the table data so that it can be updated as needed
  const [tableData, setTableData] =
    React.useState<KidsOverviewSecondLevelTableData[]>(data);
  // Create an object for handling opening the publish dialog box
  const [publishDialogIsShowing, setPublishDialogIsShowing] =
    React.useState<boolean>(false);
  // Create a state object that will store the fundId and shareClass of the documentLangauge that is being published
  const [publishingDocument, setPublishingDocument] = React.useState<{
    fundId: string;
    shareClass: string;
    documentLanguage: string;
    fundName: string;
  }>({ fundId: '', shareClass: '', documentLanguage: '', fundName: '' });
  // Use the snackbar for feedback when publishing
  const { showAsyncSnackbar } = useSnackbar();
  // Create a request client for publishing
  const requestClient = client();

  // Define a function for updating the version number in the table when a document has been published
  function updateVersionNumber(
    fundId: string,
    shareClass: string,
    documentLanguage: string,
  ) {
    // Find the index of the document in the table data
    const index = tableData.findIndex(
      (document) =>
        document.fundId === fundId &&
        document.isin === shareClass &&
        document.documentLanguage === documentLanguage,
    );
    // Create a copy of the table data
    const tableDataCopy = [...tableData];
    // Update the version number in the copy
    tableDataCopy[index].version = `${
      parseInt(tableDataCopy[index].version.split('.')[0]) + 1
    }.0.0`;
    // Update the table data
    setTableData(tableDataCopy);
  }

  // Define a function for publishing changes
  async function publishChanges(fundId: string, shareClass: string) {
    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Publishing Latest KID.',
      successMessage: 'KID Published.',
      failureMessage: 'An Error occured while publishing.',
      promiseToResolve: requestClient.get(
        `kiid_generator_publish_latest_kiid/${fundId}/${shareClass}`,
      ),
    });
  }

  const columns = buildTableColumns(
    setPublishDialogIsShowing,
    setPublishingDocument,
  );

  return (
    <>
      <CustomTable<KidsOverviewSecondLevelTableData>
        showToolbar={true}
        id={`kiids_editor_overview_table`}
        loading={data && data.length ? false : true}
        data={tableData}
        options={{
          paging: false,
          search: true,
          exportButton: false,
          exportAllData: false,
          emptyRowsWhenPaging: false,
        }}
        columns={columns}
      />
      {publishDialogIsShowing && (
        <PublishDocumentDialogBox
          setDialogIsShowing={setPublishDialogIsShowing}
          fundId={publishingDocument.fundId}
          shareClass={publishingDocument.shareClass}
          documentLanguage={publishingDocument.documentLanguage}
          fundName={publishingDocument.fundName}
          updateVersionNumber={updateVersionNumber}
        />
      )}
    </>
  );
};

export default KidsOverviewAllFunctionalitySecondLevelTable;
