import React, { FC } from 'react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface StyleFormatButtonsProps {
  handleToggleClick: (e: React.MouseEvent, inlineStyle: string) => void;
  fontSizeConfig?: {
    currentSize: number | Error;
    onSizeChange: (newSize: number) => void;
    hasCustomFontSize: boolean | Error;
    onToggleCustomFontSize: () => void;
  };
}

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '1rem',
    gap: '1.5rem',
    alignItems: 'center',
  },
  fontSizeContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    border: `1px solid ${mainColors.controlButtonBlue}`,
    borderRadius: '4px',
    padding: '0.2rem',
    backgroundColor: 'white',
  },
  sizeValue: {
    padding: '0 0.5rem',
    minWidth: '2rem',
    textAlign: 'center',
  },
  sizeButton: {
    padding: 2,
    color: mainColors.controlButtonBlue,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
      color: mainColors.controlButtonBlue_lighter,
    },
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
  sizeInput: {
    width: '3rem',
    border: 'none',
    textAlign: 'center',
    color: mainColors.controlButtonBlue,
    backgroundColor: 'transparent',
    cursor: 'text',
    '&:focus': {
      outline: 'none',
    },
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  applyFontSizeButton: {
    color: 'white',
    fontSize: '2.6rem',
    padding: '0.4rem',
    backgroundColor: mainColors.controlButtonBlue,
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: mainColors.controlButtonBlue_lighter,
    },
  },
  removeFontSizeButton: {
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    padding: 0,
    minWidth: '16px',
    width: '16px',
    height: '16px',
    backgroundColor: '#ff4444',
    color: 'white',
    visibility: 'hidden',
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      fontSize: '12px',
    },
    '&:hover': {
      backgroundColor: '#ff6666',
    },
  },
  fontSizeWrapper: {
    position: 'relative',
    display: 'inline-block',
    '&:hover .MuiIconButton-root.removeFontSizeButton': {
      visibility: 'visible',
    },
  },
  formatButton: {
    color: 'white',
    fontSize: '2.6rem',
    padding: '0.4rem',
    backgroundColor: mainColors.controlButtonBlue,
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: mainColors.controlButtonBlue_lighter,
    },
  },
  divider: {
    width: '1px',
    height: '2rem',
    backgroundColor: mainColors.controlButtonBlue,
  },
  errorIcon: {
    color: '#ff4444',
    opacity: 0.5,
    fontSize: '2.6rem',
    padding: '0.4rem',
    cursor: 'help',
  },
}));

const StyleFormatButtons: FC<StyleFormatButtonsProps> = ({
  handleToggleClick,
  fontSizeConfig,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState(
    fontSizeConfig?.currentSize.toString() ?? '',
  );
  const [showFontTooltip, setShowFontTooltip] = React.useState(false);

  React.useEffect(() => {
    if (fontSizeConfig) {
      if (fontSizeConfig.hasCustomFontSize) {
        setInputValue(fontSizeConfig.currentSize.toString());
      }
    }
  }, [fontSizeConfig?.currentSize, fontSizeConfig?.hasCustomFontSize]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      setInputValue('');
      return;
    }

    const numValue = parseFloat(value);
    if (!isNaN(numValue) && numValue >= 0) {
      const roundedValue = Math.round(numValue * 10) / 10;
      setInputValue(roundedValue.toString());
    }
  };

  const handleInputBlur = () => {
    if (!fontSizeConfig) return;

    if (inputValue === '' || isNaN(parseFloat(inputValue))) {
      setInputValue(fontSizeConfig.currentSize.toString());
      return;
    }
    const numValue = parseFloat(inputValue);
    fontSizeConfig.onSizeChange(numValue);
  };

  const handleButtonClick = (increase: boolean) => {
    if (!fontSizeConfig) return;

    const currentValue = parseFloat(
      inputValue || fontSizeConfig.currentSize.toString(),
    );
    const newValue = increase
      ? Number.isInteger(currentValue)
        ? currentValue + 1
        : Math.ceil(currentValue)
      : Number.isInteger(currentValue)
        ? currentValue - 1
        : Math.floor(currentValue);
    setInputValue(newValue.toString());
    fontSizeConfig.onSizeChange(newValue);
  };

  return (
    <div className={classes.buttonsContainer}>
      <Tooltip
        placement="top"
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        arrow
        title="Format Selected Text in Bold"
      >
        <FormatBoldIcon
          className={classes.formatButton}
          onMouseDown={(e) => handleToggleClick(e, 'BOLD')}
        />
      </Tooltip>
      <Tooltip
        placement="top"
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        arrow
        title="Underline Selected Text"
      >
        <FormatUnderlinedIcon
          className={classes.formatButton}
          onMouseDown={(e) => handleToggleClick(e, 'UNDERLINE')}
        />
      </Tooltip>
      <Tooltip
        placement="top"
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        arrow
        title="Format Selected Text in Italic"
      >
        <FormatItalicIcon
          className={classes.formatButton}
          onMouseDown={(e) => handleToggleClick(e, 'ITALIC')}
        />
      </Tooltip>
      {fontSizeConfig && (
        <>
          <div className={classes.divider} />
          {fontSizeConfig.currentSize instanceof Error ||
          fontSizeConfig.hasCustomFontSize instanceof Error ? (
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              arrow
              title={
                fontSizeConfig.currentSize instanceof Error
                  ? fontSizeConfig.currentSize.message
                  : fontSizeConfig.hasCustomFontSize instanceof Error
                    ? fontSizeConfig.hasCustomFontSize.message
                    : 'Error with font size'
              }
            >
              <ErrorOutlineIcon className={classes.errorIcon} />
            </Tooltip>
          ) : fontSizeConfig.hasCustomFontSize ? (
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              arrow
              title="Custom Font Size (px)"
              open={showFontTooltip}
              onMouseEnter={() => setShowFontTooltip(true)}
              onMouseLeave={() => setShowFontTooltip(false)}
            >
              <div className={classes.fontSizeWrapper}>
                <div className={classes.fontSizeContainer}>
                  <IconButton
                    className={classes.sizeButton}
                    onClick={() => handleButtonClick(false)}
                    size="small"
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                  <input
                    type="number"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    className={classes.sizeInput}
                    step="0.1"
                  />
                  <IconButton
                    className={classes.sizeButton}
                    onClick={() => handleButtonClick(true)}
                    size="small"
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </div>
                <Tooltip
                  placement="top"
                  classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                  arrow
                  title="Remove Custom Font Size"
                >
                  <IconButton
                    className={`${classes.removeFontSizeButton} removeFontSizeButton`}
                    onClick={fontSizeConfig.onToggleCustomFontSize}
                    size="small"
                    onMouseEnter={() => setShowFontTooltip(false)}
                    onMouseLeave={() => setShowFontTooltip(true)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              arrow
              title="Apply Custom Font Size"
              open={showFontTooltip}
              onMouseEnter={() => setShowFontTooltip(true)}
              onMouseLeave={() => setShowFontTooltip(false)}
            >
              <FormatSizeIcon
                className={classes.formatButton}
                onClick={fontSizeConfig.onToggleCustomFontSize}
              />
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export default StyleFormatButtons;
