import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import NavSection from './NavSection.component';
import { Section } from '../../../types/server-data/ClientConfig';
import { clientSectionsSelector } from '../../../redux/pages/selectors';
import makeStyles from '@mui/styles/makeStyles';
import { clientNameSelector } from '../../../redux/auth/selectors';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  scrollContainer: {
    position: 'relative',
    flex: 1,
    height: '100%',
    overflow: 'hidden',
  },
  navContainerRoot: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    overflowX: 'auto',
    gap: '1.2rem',
    overflowY: 'hidden',
  },
  fadeIndicator: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '20px',
    pointerEvents: 'none',
    zIndex: 1,
  },
  arrowBox: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    cursor: 'pointer',
    flexShrink: 0,
    margin: '0 4px',
    zIndex: 2,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      border: '1px solid #1e3c72',
      '& svg': {
        color: '#1e3c72',
      }
    },
    '& svg': {
      width: '20px',
      height: '20px',
      color: 'rgba(0, 0, 0, 0.54)',
      transition: 'color 0.2s ease-in-out',
    },
  },
  leftFade: {
    left: 0,
    background: 'linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
  },
  rightFade: {
    right: 0,
    background: 'linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
  },
});

const NavContainer: React.FC = (): ReactElement => {
  const classes = useStyles();
  const clientName = useSelector(clientNameSelector);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showLeftIndicator, setShowLeftIndicator] = useState(false);
  const [showRightIndicator, setShowRightIndicator] = useState(false);

  const clientSections = useSelector(clientSectionsSelector) || [];

  const scrollIncrement = 200; // Pixels to scroll on each click

  const handleScrollLeft = () => {
    if (!containerRef.current) return;
    const newScrollPosition = containerRef.current.scrollLeft - scrollIncrement;
    containerRef.current.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    });
  };

  const handleScrollRight = () => {
    if (!containerRef.current) return;
    const newScrollPosition = containerRef.current.scrollLeft + scrollIncrement;
    containerRef.current.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    });
  };

  const checkForOverflow = () => {
    const container = containerRef.current;
    if (!container) return;

    const hasLeftOverflow = container.scrollLeft > 0;
    const hasRightOverflow = 
      Math.ceil(container.scrollLeft + container.clientWidth) < container.scrollWidth;

    setShowLeftIndicator(hasLeftOverflow);
    setShowRightIndicator(hasRightOverflow);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
      container.scrollLeft += e.deltaY;
      checkForOverflow();
    };

    // Check for overflow initially and on resize
    checkForOverflow();
    window.addEventListener('resize', checkForOverflow);
    container.addEventListener('wheel', handleWheel);
    container.addEventListener('scroll', checkForOverflow);

    return () => {
      window.removeEventListener('resize', checkForOverflow);
      container.removeEventListener('wheel', handleWheel);
      container.removeEventListener('scroll', checkForOverflow);
    };
  }, []);

  // Quick hack for Peter to remove the performance diagnostics page from the PA section for Trium while keeping the titlebar options
  const updatedClientSections =
    clientName === 'trium'
      ? clientSections.map((section) => {
          if (section.sectionId === 'pa') {
            return {
              ...section,
              pages: section.pages.filter(
                (page) => page !== 'performance-diagnostics',
              ),
            };
          }
          return section;
        })
      : clientSections;

  return (
    <div className={classes.wrapper}>
      {showLeftIndicator && (
        <Tooltip title="More sections..." placement="bottom">
          <div className={classes.arrowBox} onClick={handleScrollLeft}>
            <ChevronLeftIcon />
          </div>
        </Tooltip>
      )}
      <div className={classes.scrollContainer}>
        <div ref={containerRef} className={`${classes.navContainerRoot} noscrollbar`}>
          {showLeftIndicator && (
            <div className={`${classes.fadeIndicator} ${classes.leftFade}`} />
          )}
          {updatedClientSections.map((section: Section) => {
            return <NavSection key={section.sectionId} section={section} />;
          })}
          {showRightIndicator && (
            <div className={`${classes.fadeIndicator} ${classes.rightFade}`} />
          )}
        </div>
      </div>
      {showRightIndicator && (
        <Tooltip title="More sections..." placement="bottom">
          <div className={classes.arrowBox} onClick={handleScrollRight}>
            <ChevronRightIcon />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default NavContainer;
