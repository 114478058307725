import React, { ReactElement } from 'react';
import RaptorLoading from '../feedback/RaptorLoading';
import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  loadingWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    opacity: 0.1,
    backgroundColor: 'red',
    height: (props) => props.loadingHeight,
  },
  loadingIcon: {
    zIndex: 12,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

interface Props {
  loading: boolean;
  style?: React.CSSProperties;
  loadingHeight?: string | number | undefined;
  error?: number | string | null;
}

interface StyleProps {
  loading: boolean;
  loadingHeight: string | number;
}

function GeneralLoadingCover({
  loading,
  children,
  ...props
}: React.PropsWithChildren<Props>): ReactElement {
  const classes = useStyles({
    loading,
    loadingHeight: props.loadingHeight || '20rem',
  } as StyleProps);
  return (
    <>
      {loading ? (
        <div className={classes.loadingWrapper} style={{ ...props.style }}>
          <RaptorLoading />
        </div>
      ) : props.error ? (
        <div className={classes.loadingWrapper} style={{ ...props.style }}>
          <Typography variant="h3" style={{ fontSize: '1.2rem' }}>
            {props.error === 404
              ? 'No data was found on the server'
              : 'There was an error fetching data from the server'}
          </Typography>
        </div>
      ) : (
        children
      )}
    </>
  );
}

export default GeneralLoadingCover;
