import { makeStyles } from '@mui/styles';
import { mainColors } from '../../../../../styling/theme';

export const useKidsEditorStyles = makeStyles(() => ({
  editorPageParent: {
    display: 'flex',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    gap: '5rem',
    justifyContent: 'center',
    height: '100%',
  },
  editorContainer: {
    maxWidth: '80rem',
    width: '100%',
    padding: '0 1rem',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.2rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.mainBlue,
      borderRadius: '1rem',
    },
  },
  viewerContainer: {
    width: '100%',
  },
}));

export const useEditorTileStyles = makeStyles(() => ({
  textEditorBox: {
    marginTop: '2rem',
    marginBottom: '2rem',
    marginLeft: '1rem',
    fontSize: '1.5rem',
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '10rem',
  },
  outerContainer: {
    border: '2px solid',
    borderColor: mainColors.mainBlue,
    backgroundColor: 'white',
    padding: '1rem',
  },
  editorContainer: {
    display: 'flex',
    borderRadius: '6px',
    flexDirection: 'row',
    width: '100%',
    marginRight: '1rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '1rem',
    gap: '2rem',
  },
  button: {
    height: '3rem',
    marginRight: '1rem',
    width: '15rem',
  },
}));
