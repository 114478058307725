import React, { FC, useEffect, useState } from 'react';
import { mainColors } from '../../../../../styling/theme';
import KidsEditorGridItem from '../layout/KidsEditorGridItem.component';
import Raptor2Loading from '../../../../feedback/Raptor2Loading';
import useRouteParams from '../../../../../hooks/useRouteParams';
import { useDispatch } from 'react-redux';
import {
  toggleControlBarShowing,
  updateControlBarComponents,
} from '../../../../../redux/ui/controlbar/actions';
import ReviewDocumentsButtons from '../../components/buttons/ReviewDocumentsButtons.component';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import ReviewConfirmationDialogBox from '../../components/buttons/ReviewConfirmationDialogBox.component';
import { Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import BackToOverviewBtn from '../../components/buttons/BackToOverviewBtn';
import { useRaidrKids } from '../context/RaidrKidsContext';
import { KIDOverviewData } from '../../../../../types/pages/raidr.types';
import { useKIDSOverviewData, useKIDSReviewData } from '../services/queries';
import { useGenerateNewReviewKIDPdf } from '../services/mutations';
import { useRaidrViewStyles } from '../styles/Raidr.styles';

function findDefaultSelection(
  fundId: string,
  isin: string,
  priipsOverviewData: any[],
) {
  if (priipsOverviewData.length === 0) {
    return '';
  }
  let defaultSelection = '';
  priipsOverviewData.forEach((item) => {
    if (item['fund_id_string'] === fundId && item['share_class'] === isin) {
      defaultSelection = `${item['fund_name']} - ${item['share_class']}`;
    }
  });
  return defaultSelection;
}

const KidsReview: FC = () => {
  const classes = useRaidrViewStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isPriips, backToOverviewLink, hasEditPermissions } = useRaidrKids();

  const { data: priipsOverviewData, isPending, error } = useKIDSOverviewData();
  const createNewReviewKIDPdf = useGenerateNewReviewKIDPdf();

  const [reviewContentData, setReviewContentData] = useState<any[]>([]);

  // Variable used to display the confirm review dialog box.
  const [confirmReviewIsShowing, setConfirmReviewIsShowing] =
    useState<boolean>(false);

  const [defaultSelection, setDefaultSelection] = React.useState<string>('');
  const [filteredOverviewData, setFilteredOverviewData] = React.useState<
    KIDOverviewData[]
  >([]);

  // Get route params
  const fundId = useRouteParams('fundId')['fundId'];
  const isin = useRouteParams('isin')['isin'];
  const documentLanguage =
    useRouteParams('documentLanguage')['documentLanguage'];
  const currentVersion = useRouteParams('currentVersion')['currentVersion'];
  const publishedVersion =
    useRouteParams('publishedVersion')['publishedVersion'];

  const { data } = useKIDSReviewData(
    fundId,
    isin,
    currentVersion,
    publishedVersion,
    documentLanguage,
  );

  useEffect(() => {
    if (data?.data) setReviewContentData(data?.data);
  }, [data?.data]);

  const getPDF = async () => {
    // Check if the required fields have been filled in.
    if (!reviewContentData || !reviewContentData.length) {
      return;
    }

    // Create an object of formData
    const formData = new FormData();
    // Convert the content to json
    const kidsJson = JSON.stringify(reviewContentData);
    // Update the formData object.
    formData.append('contents', kidsJson);
    formData.append('params', JSON.stringify(['contents']));

    createNewReviewKIDPdf
      .mutateAsync(formData)
      .then((response: { data: BlobPart }) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const file = window.URL.createObjectURL(blob);
        const iframe = document.querySelector('iframe');
        if (iframe?.src) {
          iframe.src = file;
          iframe.title = 'kid.pdf';
        }
      });
  };

  useEffect(() => {
    if (reviewContentData.length) {
      getPDF();
    }
  }, [reviewContentData]);

  const backToOverview = () => {
    dispatch(toggleControlBarShowing(false));
    navigate(backToOverviewLink);
  };

  // Toggle the control bar to be shown
  useEffect(() => {
    if (reviewContentData.length > 0) {
      dispatch(
        updateControlBarComponents([
          <BackToOverviewBtn
            backToOverview={backToOverview}
            dontSetPosition
            key="back_to_overview"
          />,
          <ReviewDocumentsButtons
            key="review_documents_buttons"
            filteredOverviewData={filteredOverviewData}
            isPriipsKids={isPriips}
          >
            {hasEditPermissions && (
              <Tooltip
                placement="top"
                enterDelay={1000}
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                arrow
                title="Go to Edit page for this document."
              >
                <Button
                  variant="contained"
                  sx={{
                    fontSize: '1.5rem',
                    width: '25rem',
                    height: '3rem',
                    color: 'white',
                    backgroundColor: mainColors.mainBlue,
                    borderRadius: '0.2rem',
                    padding: '0.2rem',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: mainColors.mainBlue_lighter,
                    },
                    fontWeight: 600,
                    textTransform: 'none',
                  }}
                  disableElevation={true}
                  onClick={() =>
                    navigate(
                      isPriips
                        ? `/raidr/priips-kids-editor?fundId=${fundId}&isin=${isin}&documentLanguage=${documentLanguage}`
                        : `/raidr/ucits-kiids-editor?fundId=${fundId}&isin=${isin}&documentLanguage=${documentLanguage}`,
                    )
                  }
                  endIcon={
                    <EditIcon
                      sx={{
                        padding: '0.2rem',
                        fontSize: '3rem',
                        width: '2.5rem',
                        height: '2.5rem',
                      }}
                    />
                  }
                >
                  Edit Document
                </Button>
              </Tooltip>
            )}
          </ReviewDocumentsButtons>,
        ]),
      );
      dispatch(toggleControlBarShowing(true));
    }
  }, [reviewContentData, filteredOverviewData]);

  // Create an array of options for the select when overview data is available
  useEffect(() => {
    if (!priipsOverviewData) return;
    if (priipsOverviewData.length > 0) {
      const overviewDataFiltered: any[] = [];
      priipsOverviewData.forEach((item) => {
        // Only add documents that are available for review.
        const version = item['version'].split('.');
        if (
          version[2] !== '0' &&
          item['document_language'] === documentLanguage
        ) {
          overviewDataFiltered.push(item);
        }
      });

      setFilteredOverviewData(overviewDataFiltered);
      // Set the default selection
      const selctionValue = findDefaultSelection(
        fundId || '',
        isin || '',
        priipsOverviewData,
      );
      setDefaultSelection(selctionValue);
    }
  }, [priipsOverviewData?.length]);

  return (
    <>
      <KidsEditorGridItem xs={12}>
        <div className={classes.viewerContainer}>
          <div className={classes.iframeContainer}>
            <iframe
              src=""
              width={createNewReviewKIDPdf.isPending ? '0%' : '100%'}
              height="100%"
              title={'kid.pdf'}
              id={'review-iframe'}
            ></iframe>
            {createNewReviewKIDPdf.isPending ? (
              <Raptor2Loading
                centerWrap
                messages={['Generating Document...']}
              />
            ) : null}
          </div>
        </div>
      </KidsEditorGridItem>
      <KidsEditorGridItem xs={12}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {confirmReviewIsShowing && (
            <ReviewConfirmationDialogBox
              setDialogIsShowing={setConfirmReviewIsShowing}
              dialogIsShowing={confirmReviewIsShowing}
              fundId={fundId || ''}
              shareClass={isin || ''}
              documentLanguage={documentLanguage || ''}
              filteredOverviewData={filteredOverviewData}
              fundName={defaultSelection.split(' - ')[0]}
              setFilteredOverviewData={setFilteredOverviewData}
              backToOverviewLink={backToOverviewLink}
              hasEditBtn={isPriips}
              isPriips={isPriips}
            />
          )}
          <Tooltip
            placement="top"
            enterDelay={1000}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            arrow
            title="Confirm Review"
          >
            <Button
              variant="contained"
              sx={{
                fontSize: '1.5rem',
                width: '18rem',
                height: '3rem',
                color: 'white',
                backgroundColor: mainColors.mainBlue,
                borderRadius: '0.2rem',
                padding: '0.2rem',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: mainColors.mainBlue_lighter,
                },
                fontWeight: 600,
                textTransform: 'none',
              }}
              disableElevation={true}
              onClick={() => setConfirmReviewIsShowing(true)}
              endIcon={
                <CheckIcon
                  sx={{
                    padding: '0.2rem',
                    fontSize: '3rem',
                    width: '2.5rem',
                    height: '2.5rem',
                  }}
                />
              }
            >
              Complete Review
            </Button>
          </Tooltip>
        </div>
      </KidsEditorGridItem>
    </>
  );
};

export default KidsReview;
