import * as React from 'react';
import { useDispatch } from 'react-redux';

import { FormEvent, useEffect, useState } from 'react';
import { greys, mainColors } from '../../../styling/theme';
import { logOut } from '../../../redux/auth/actions';
import RaptorWithRs from '../../../images/RaptorWithRs';
import { zIndexes } from '../../../styling/zIndexes';
import { useLocation } from 'react-router-dom';
import requestClient from '../../../utilities/requestClient';
import jwt_decode from 'jwt-decode';
import { validatePassword } from '../../../utilities/accountDetailsValidation';
import clsx from 'clsx';
import { ClassNameMap, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Help, Visibility, VisibilityOff } from '@mui/icons-material';
import FastAPIInstance from '../../../services/risksystem3-public-api';

const useStyles = makeStyles(() => ({
  mainContainer: {
    padding: '12vh 8vw',
    minWidth: 920,
    position: 'relative',
    height: '100%',
    zIndex: zIndexes.content,
  },
  raptorWithRs: {
    width: 200,
    marginBottom: '2rem',
  },
  pageTitle: {
    fontSize: '2.4rem',
    fontWeight: 500,
    color: mainColors.mainBlue,
  },
  toLogin: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    padding: '0 0 1rem 0',
    color: greys.grey600,
    fontWeight: 500,
    '& a': {
      color: mainColors.mainBlue,
      textDecoration: 'none',
      fontWeight: 300,
    },
  },
  errorTitle: {
    fontSize: '1.6rem',
    fontWeight: 500,
    marginBottom: '1rem',
    color: mainColors.Fail,
    width: '32rem',
  },
  description: {
    fontSize: '1.4rem',
    fontWeight: 400,
    marginBottom: '1rem',
    color: greys.grey600,
    width: '32rem',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem',
  },
  input: {
    padding: '1rem 0.5rem',
    borderRadius: '0.4rem',
    border: `1px solid ${greys.grey100}`,
    fontSize: '1.5rem',
    fontWeight: 400,
    color: greys.grey800,
    '&::placeholder': {
      color: greys.grey400,
    },
    width: '32rem',
  },
  input_password: {
    padding: '1rem 4rem 1rem 0.5rem',
    borderRadius: '0.4rem',
    border: `1px solid ${greys.grey100}`,
    fontSize: '1.5rem',
    fontWeight: 400,
    color: greys.grey800,
    '&::placeholder': {
      color: greys.grey400,
    },
    width: '32rem',
  },
  inputGroup: {
    padding: '1rem 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  email: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.mainBlue_lighter,
    padding: '0.5rem 0',
  },
  validationMessage: {
    fontSize: '1.2rem',
    fontWeight: 400,
    color: mainColors.Fail,
  },
  resendLink: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    padding: '0 0 0.5rem 0',
    color: greys.grey600,
    fontWeight: 300,
    '& a': {
      color: mainColors.mainBlue,
      textDecoration: 'none',
    },
  },
  submitButton: {
    all: 'unset',
    marginTop: '1rem',
    width: '32rem',
    padding: '1rem 0',
    borderRadius: '0.4rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    color: 'white',
    cursor: 'pointer',
    backgroundColor: mainColors.mainBlue,
    textAlign: 'center',
  },
  submitButton_inactive: {
    marginTop: '1rem',
    width: '32rem',
    padding: '1rem 0',
    borderRadius: '0.4rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    color: 'white',
    backgroundColor: greys.grey300,
    textAlign: 'center',
  },
  newPasswordGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  input_valid: {
    border: `1px solid ${mainColors.Pass}`,
    backgroundColor: mainColors.Pass_veryLight,
  },
  input_not_valid: {
    border: `1px solid ${mainColors.Fail}`,
    backgroundColor: mainColors.Fail_veryLight,
  },
  inputValidationMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '0.5rem',
    '& svg': {
      fontSize: '1.6rem',
      color: greys.grey600,
    },
  },
  inputValidationMessage: {
    fontSize: '1.2rem',
    fontWeight: 400,
    color: mainColors.mainBlue,
  },
  inputValidationMessage_valid: {
    color: mainColors.Pass,
  },
  inputValidationMessage_not_valid: {
    color: mainColors.Fail,
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  tooltipTitle: {
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  tooltipItem: {
    fontSize: '1rem',
  },
  passwordInput: {
    width: 'fit-content',
    position: 'relative',
  },
  showPasswordButton: {
    position: 'absolute',
    right: '1rem',
    top: '50%',
    transform: 'translateY(-40%)',
    cursor: 'pointer',
    '& svg': {
      fontSize: '2.5rem',
      color: greys.grey600,
    },
  },
  feedbackContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    marginTop: '1rem',
  },
  authMessage: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: greys.grey600,
    width: '32rem',
  },
}));

const passwordValidationStyles = (password: string, classes: ClassNameMap) => {
  if (password.length === 0) {
    return classes.input_password;
  } else if (password.length > 0 && validatePassword(password)) {
    return clsx(classes.input_password, classes.input_valid);
  } else {
    return clsx(classes.input_password, classes.input_not_valid);
  }
};

const confirmPasswordValidation = (
  password: string,
  confirmPassword: string,
  classes: ClassNameMap,
) => {
  if (confirmPassword.length === 0) {
    return classes.input;
  } else if (
    confirmPassword.length > 0 &&
    validatePassword(confirmPassword) &&
    password === confirmPassword
  ) {
    return clsx(classes.input, classes.input_valid);
  } else {
    return clsx(classes.input, classes.input_not_valid);
  }
};

const getAuthMessageColor = (authStatus: 'loading' | 'success' | 'error') => {
  switch (authStatus) {
    case 'loading':
      return greys.grey600;
    case 'success':
      return mainColors.Pass;
    case 'error':
      return mainColors.Fail;
  }
};

const ResetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const [authMessage, setAuthMessage] = useState<string>('');
  const [authStatus, setAuthStatus] = useState<'loading' | 'error' | 'success'>(
    'loading',
  );
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [tokenExpired, setTokenExpired] = useState<boolean>(false);

  const [tokenValid, setTokenValid] = useState<
    'no_token' | 'invalid' | 'valid'
  >('no_token');
  const [tokenEmail, setTokenEmail] = useState<string>('');
  const resetToken = location.search.split('token=')[1];

  // this function takes the user input for username and auto formats it with our desired format
  // currently this just means making all characters lowercase
  const handleEmailInputEnforcement = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEmail(event.target.value.toLowerCase());
  };

  useEffect(() => {
    dispatch(logOut());
    if (resetToken) {
      try {
        const decryptedToken: any = jwt_decode(resetToken);
        setTokenEmail(decryptedToken.user_email);
        setTokenValid('valid');
      } catch (error) {
        setTokenValid('invalid');
      }
    } else {
      setTokenValid('no_token');
    }
  }, []);

  const handleSendRequest = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setAuthStatus('loading');
    setAuthMessage('Submitting reset request...');
    FastAPIInstance
      .post('/v1/internal_user_management/update_user_passwd_invitation', {
        user_email: email,
      })
      .then((res) => {
        if (res.status === 200) {
          setAuthStatus('success');
          setAuthMessage('Password reset link sent.');
        } else {
          setAuthStatus('error');
          setAuthMessage('Unknown Error. Please contact RiskSystem support.');
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          setAuthStatus('error');
          setAuthMessage('Chosen email does not belong to an existing user.');
        } else {
          setAuthStatus('error');
          setAuthMessage('Unknown Error. Please contact RiskSystem support.');
        }
      });
  };

  const handleNewPassword = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setValidationMessage('Passwords do not match');
      return;
    } else if (
      !validatePassword(password) ||
      !validatePassword(confirmPassword)
    ) {
      setValidationMessage('Password not valid');
      return;
    } else {
      setAuthStatus('loading');
      setValidationMessage('');
      setAuthMessage('Submitting new password...');
      FastAPIInstance
        .put(
          '/v1/internal_user_management/update_forget_passwd',
          {
            user_password: password,
          },
          {
            headers: {
              Authorization: 'Bearer ' + resetToken,
            },
          },
        )
        .then((res) => {
          if (res.status === 200) {
            setAuthStatus('success');
            setAuthMessage('Password updated successfully.');
            setTimeout(() => {
              window.location.href = '/raptor/login';
            }, 500);
          } else {
            setAuthStatus('error');
            setAuthMessage('Unknown Error. Please contact RiskSystem support.');
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            setAuthStatus('error');
            setAuthMessage('Reset link expired. Please resend.');
            setTokenExpired(true);
          } else {
            setAuthStatus('error');
            setAuthMessage('Unknown Error. Please contact RiskSystem support.');
          }
        });
    }
  };

  return (
    <div className={classes.mainContainer}>
      <RaptorWithRs className={classes.raptorWithRs} />
      <div>
        <div className={classes.pageTitle}>Reset Password</div>
        <div className={classes.toLogin}>
          <div>Go to:</div>
          <a href="/raptor/login">Login Page.</a>
        </div>
        {tokenValid === 'valid' ? (
          <>
            <div className={classes.email}>{tokenEmail}</div>
            <div className={classes.description}>
              Please choose a new password.
            </div>
            <form
              onSubmit={handleNewPassword}
              autoComplete="off"
              className={classes.inputsContainer}
            >
              <div className={classes.inputGroup}>
                <div className={classes.newPasswordGroup}>
                  <div className={classes.passwordInput}>
                    <input
                      id="password-input"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="New Password"
                      autoComplete="new-password"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={passwordValidationStyles(password, classes)}
                    />
                    <div
                      className={classes.showPasswordButton}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </div>
                  </div>
                  <div className={classes.inputValidationMessageContainer}>
                    <Tooltip
                      title={
                        <div className={classes.tooltipContainer}>
                          <div className={classes.tooltipTitle}>
                            For security, we enforce the following password
                            rules:
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must be at least 12 characters long.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 uppercase letter.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 lowercase letter.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 number.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 special character.
                          </div>
                        </div>
                      }
                      placement="right-end"
                    >
                      <Help />
                    </Tooltip>
                    {password.length > 0 ? (
                      validatePassword(password) ? (
                        <div
                          className={clsx(
                            classes.inputValidationMessage,
                            classes.inputValidationMessage_valid,
                          )}
                        >
                          Password valid.
                        </div>
                      ) : (
                        <div
                          className={clsx(
                            classes.inputValidationMessage,
                            classes.inputValidationMessage_not_valid,
                          )}
                        >
                          Password not valid.
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
                <div className={classes.newPasswordGroup}>
                  <div className={classes.passwordInput}>
                    <input
                      id="confirm-password-input"
                      type={showConfirmPassword ? 'text' : 'password'}
                      placeholder="Confirm Password"
                      autoComplete="new-password"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className={confirmPasswordValidation(
                        password,
                        confirmPassword,
                        classes,
                      )}
                    />
                    <div
                      className={classes.showPasswordButton}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </div>
                  </div>
                  <div className={classes.inputValidationMessageContainer}>
                    <Tooltip
                      title={
                        <div className={classes.tooltipContainer}>
                          <div className={classes.tooltipTitle}>
                            For security, we enforce the following password
                            rules:
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must be at least 12 characters long.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 uppercase letter.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 lowercase letter.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 number.
                          </div>
                          <div className={classes.tooltipItem}>
                            {' '}
                            - Must contain at least 1 special character.
                          </div>
                        </div>
                      }
                      placement="right-end"
                    >
                      <Help />
                    </Tooltip>
                    {confirmPassword.length > 0 ? (
                      validatePassword(confirmPassword) ? (
                        password === confirmPassword ? (
                          <div
                            className={clsx(
                              classes.inputValidationMessage,
                              classes.inputValidationMessage_valid,
                            )}
                          >
                            Passwords match.
                          </div>
                        ) : (
                          <div
                            className={clsx(
                              classes.inputValidationMessage,
                              classes.inputValidationMessage_not_valid,
                            )}
                          >
                            Passwords do not match.
                          </div>
                        )
                      ) : (
                        <div
                          className={clsx(
                            classes.inputValidationMessage,
                            classes.inputValidationMessage_not_valid,
                          )}
                        >
                          Password not valid.
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={classes.validationMessage}>
                {validationMessage}
              </div>
              {validatePassword(password) &&
              validatePassword(confirmPassword) &&
              password === confirmPassword ? (
                <button type="submit" className={classes.submitButton}>
                  Reset
                </button>
              ) : (
                <div className={classes.submitButton_inactive}>Reset</div>
              )}
              <div className={classes.feedbackContainer}>
                {authMessage && (
                  <div
                    className={classes.authMessage}
                    style={{
                      color: getAuthMessageColor(authStatus),
                    }}
                  >
                    {authMessage}
                  </div>
                )}
              </div>
              {tokenExpired ? (
                <div className={classes.resendLink}>
                  <a href="/raptor/reset-password">
                    Click here to request another reset email.
                  </a>
                </div>
              ) : null}
            </form>
          </>
        ) : null}
        {tokenValid === 'no_token' ? (
          <>
            <div className={classes.description}>
              Enter email address to receive a password reset link.
            </div>
            <>
              <form
                onSubmit={handleSendRequest}
                autoComplete="off"
                className={classes.inputsContainer}
              >
                <div className={classes.inputGroup}>
                  <input
                    id="email-input"
                    type="email"
                    placeholder="Email"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    value={email}
                    onChange={(e) => handleEmailInputEnforcement(e)}
                    className={classes.input}
                  />
                </div>
                {email.length > 0 ? (
                  <button type="submit" className={classes.submitButton}>
                    Send Request
                  </button>
                ) : (
                  <div className={classes.submitButton_inactive}>
                    Send Request
                  </div>
                )}
                <div className={classes.feedbackContainer}>
                  {authMessage && (
                    <div
                      className={classes.authMessage}
                      style={{
                        color: getAuthMessageColor(authStatus),
                      }}
                    >
                      {authMessage}
                    </div>
                  )}
                </div>
              </form>
            </>
          </>
        ) : null}
        {tokenValid === 'invalid' ? (
          <>
            <div className={classes.errorTitle}>
              Password reset token is invalid.
            </div>
            <div className={classes.description}>
              Please re-try email address to receive a password reset link. If
              the issue persists, please contact RiskSystem support.
            </div>
            <form
              onSubmit={handleSendRequest}
              autoComplete="off"
              className={classes.inputsContainer}
            >
              <div className={classes.inputGroup}>
                <input
                  id="email-input"
                  type="email"
                  placeholder="Email"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  value={email}
                  onChange={(e) => handleEmailInputEnforcement(e)}
                  className={classes.input}
                />
              </div>
              {email.length > 0 ? (
                <button type="submit" className={classes.submitButton}>
                  Send Request
                </button>
              ) : (
                <div className={classes.submitButton_inactive}>
                  Send Request
                </div>
              )}
              <div className={classes.feedbackContainer}>
                {authMessage && (
                  <div
                    className={classes.authMessage}
                    style={{
                      color: getAuthMessageColor(authStatus),
                    }}
                  >
                    {authMessage}
                  </div>
                )}
              </div>
            </form>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ResetPassword;
