import { FC, useEffect, useState } from 'react';
// import client from '../../../../../utilities/requestClient';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import PublishIcon from '@mui/icons-material/Publish';
import client from '../../../../../utilities/requestClient';
import useSnackbar from '../../../../../hooks/useSnackbar';
import { greys } from '../../../../../styling/theme';

interface FactSheetsOverviewData {
  fundId: string;
  fundIdString: string;
  fundName: string;
  lastEditTimestamp: string;
  lastEditedBy: string;
  version: string;
  numUnresolvedComments: number;
  versionNumbers: string[];
  language: string;
}

function buildOverviewData(data: any): FactSheetsOverviewData[] {
  if (!data) return [];
  const tableData: FactSheetsOverviewData[] = [];
  data.forEach((element: any) => {
    tableData.push({
      fundId: element.fund_id,
      fundIdString: element.fund_id_string,
      fundName: element.fund_name,
      lastEditTimestamp: element.last_edit_timestamp,
      lastEditedBy: element.last_edited_by_name,
      version: element.version,
      numUnresolvedComments: element.num_unresolved_comments,
      versionNumbers: JSON.parse(element.version_numbers),
      language: element.language,
    });
  });
  return tableData;
}

function buildOverviewColumns(): CustomColumn<FactSheetsOverviewData>[] {
  const navigate = useNavigate();
  // Create a request client for publishing
  const requestClient = client();

  // Use the snackbar for feedback when publishing
  const { showAsyncSnackbar } = useSnackbar();
  // Define a function for publishing changes
  // <fund_id>/<document_type>/<language></language>
  async function publishChanges(fundId: string, language: string) {
    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Publishing Latest KID.',
      successMessage: 'KID Published.',
      failureMessage: 'An Error occured while publishing.',
      promiseToResolve: requestClient.get(
        `pulish_latest_raidr_document_version/${fundId}/fact_sheet/${language}`,
      ),
    });
  }
  const columns: CustomColumn<FactSheetsOverviewData>[] = [
    {
      title: 'Fund ID',
      field: 'fundIdString',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Document Language',
      field: 'language',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Last Edited Timestamp',
      field: 'lastEditTimestamp',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: FactSheetsOverviewData) =>
        rowData.lastEditTimestamp.substring(0, 16).replace('T', ' '),
    },
    {
      title: 'Last Edited By',
      field: 'lastEditedBy',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Version',
      field: 'version',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Edit',
      field: 'edit',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: FactSheetsOverviewData) => (
        <Button
          variant="contained"
          onClick={() => {
            navigate(
              `fact-sheets-editor?fundId=${rowData.fundId}&fundCode=${rowData.fundIdString}&fundName=${rowData.fundName}`,
            );
          }}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
      ),
    },
    {
      title: 'Publish',
      field: 'publish',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: FactSheetsOverviewData) => (
        <Button
          variant="contained"
          sx={{
            backgroundColor:
              rowData.version === '1.0.0' ||
              (rowData.version.split('.')[2] === '0' &&
                rowData.version.split('.')[1] === '0') ||
              rowData.version.split('.')[1] === '0'
                ? greys.grey400
                : undefined,
            '&:hover': {
              backgroundColor:
                rowData.version === '1.0.0' ||
                (rowData.version.split('.')[2] === '0' &&
                  rowData.version.split('.')[1] === '0') ||
                rowData.version.split('.')[1] === '0'
                  ? greys.grey400
                  : undefined,
            },
          }}
          onClick={
            (rowData.version.split('.')[2] === '0' &&
              rowData.version.split('.')[1] === '0') ||
            rowData.version.split('.')[1] === '0'
              ? () => {
                  publishChanges(rowData.fundId, rowData.language);
                }
              : undefined
          }
          startIcon={<PublishIcon />}
        >
          Publish
        </Button>
      ),
    },
  ];
  return columns;
}

const FactSheetsOverview: FC = () => {
  // Need to add this here for the moment until the routing is improved
  const requestClient = client();
  const [factSheetsData, setFactSheetsData] = useState<any[] | null>(null);
  const getDelegeatedActsData = async () => {
    requestClient.get('get_raidr_overview_data/fact_sheet').then((response) => {
      setFactSheetsData(response.data);
    });
  };
  useEffect(() => {
    getDelegeatedActsData();
  }, []);

  const tableData = buildOverviewData(factSheetsData);
  const columns = buildOverviewColumns();

  return (
    <GridItem xs={12} card>
      <CustomTable<FactSheetsOverviewData>
        showToolbar={true}
        id={`raidr_overview_table`}
        loading={factSheetsData && factSheetsData.length ? false : true}
        data={tableData}
        options={{
          paging: false,
          search: true,
          exportButton: false,
          exportAllData: false,
          emptyRowsWhenPaging: false,
          showTitle: true,
        }}
        title={'Fact Sheets'}
        columns={columns}
      />
    </GridItem>
  );
};

export default FactSheetsOverview;
