import React, { FC } from 'react';

import Mersenne from '../../../../images/clientLogos/prime2.png';
import AdepaLogo from '../../../../images/clientLogos/adepa.png';
import Coutts from '../../../../images/clientLogos/coutts.png';
import CorumButler from '../../../../images/clientLogos/corumbutler.png';
import Gemini from '../../../../images/clientLogos/gemini.png';
import wCapital from '../../../../images/clientLogos/amadeus.png'; // WCapital are now Amadeus
import quayside from '../../../../images/clientLogos/quayside.png';
import Trium from '../../../../images/clientLogos/trium.png';
import Gem from '../../../../images/clientLogos/gem.png';
import Nikko from '../../../../images/clientLogos/smbc_nikko.jpeg';
import Bflexion from '../../../../images/clientLogos/bflexion.png';
import Pure from '../../../../images/clientLogos/pure_capital.png';
import GreshamHouse from '../../../../images/clientLogos/greshamhouse.png';
import BuyAndHold from '../../../../images/clientLogos/buy_and_hold.png';
import EPIC from '../../../../images/clientLogos/epic.svg';

interface LogoRenderProps {
  clientName: string | null;
  configName: string | null;
}

export function handleLogo(
  clientName: string | null,
  configName: string | null,
) {
  // Handle for sub-accounts on adepa with different logos (eventually this should be handled on the backend)
  if (clientName === 'adepa') {
    switch (configName) {
      case 'buyandhold_funds':
        return BuyAndHold;
      default:
        return AdepaLogo;
    }
  }

  switch (clientName) {
    case 'mersenne':
      return Mersenne;
    case 'quayside':
      return quayside;
    case 'gemini':
      return Gemini;
    case 'wcapital':
      return wCapital;
    case 'coutts':
      return Coutts;
    case 'gem':
      return Gem;
    case 'butler':
      return CorumButler;
    case 'trium':
      return Trium;
    case 'bflexion':
      return Bflexion;
    case 'snif':
      return Nikko;
    case 'pure':
      return Pure;
    case 'greshamhouse':
      return GreshamHouse;
    case 'epic_funds':
      return EPIC;
    default:
      return Mersenne;
  }
}

const LogoRender: FC<LogoRenderProps> = ({ clientName, configName }) => {
  const Logo = handleLogo(clientName, configName);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center', // Vertically centers the image
        height: '3.25em',
      }}
    >
      <img
        alt={`${clientName} logo`}
        style={{
          right: 0, // Pushes the image to the right edge
          height: '100%',
          maxWidth: '12rem',
          objectFit: 'contain',
        }}
        src={Logo}
      />
    </div>
  );
};

export default LogoRender;
