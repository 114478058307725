import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import { mainColors } from '../../../../../styling/theme';
import UtilityButtons from '../buttons/UtilityButtons.component';
import KidsEditorGridItem from '../../kids/layout/KidsEditorGridItem.component';
import KidsFieldTypeSelect from '../buttons/KidsFieldTypeSelect.component';
import { EditorTileProps } from '../../kids/editor/editorTiles/KidsEditorColumn.component';

const useStyles = makeStyles(() => ({
  pageBreakIconBox: {
    marginTop: '2rem',
    marginBottom: '2rem',
    marginLeft: '1rem',
    fontSize: '1.5rem',
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '10rem',
    display: 'flex',
    justifyContent: 'space-around',
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: '1rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: '34rem',
  },
}));

const PageBreakEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  updateFieldType,
  moveField,
  deleteField,
}) => {
  // Store the index of the current element in the overall data
  const index = priipsKidsElement.kiidIndex;
  // Store the field ID of the element
  const fieldId = priipsKidsElement.fieldId;
  const classes = useStyles();

  return (
    <KidsEditorGridItem xs={12}>
      <div className={classes.formatContainer}>
        <KidsFieldTypeSelect
          initialType={priipsKidsElement.tag}
          updateFieldType={updateFieldType}
          index={priipsKidsElement.kiidIndex}
        />
      </div>
      <div className={classes.editorContainer}>
        <div className={classes.pageBreakIconBox}>
          <InsertPageBreakIcon
            sx={{
              color: mainColors.mainBlue,
              fontSize: '10rem',
              cursor: 'pointer',
              backgroundColor: 'white',
              border: '1px solid',
              borderColor: mainColors.mainBlue,
              borderRadius: 4,
            }}
          />
        </div>
        <UtilityButtons
          moveField={moveField}
          index={index}
          deleteField={deleteField}
          fieldId={fieldId}
        />
      </div>
    </KidsEditorGridItem>
  );
};

export default PageBreakEditorTile;
