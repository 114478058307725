import TopBar from '../topBar/TopBar';
import { mainColors } from '../../../styling/theme';
import RaptorSnackbar from '../../feedback/raptorSnackbar/Snackbar.component';
import ControlBar from '../controlBar/ControlBar.component';
import { useSelector } from 'react-redux';
import { isControlBarShowingSelector } from '../../../redux/ui/controlbar/selectors';
import makeStyles from '@mui/styles/makeStyles';
import { RaidrKidsProvider } from '../../pages/raidr/kids/context/RaidrKidsContext';
import { Outlet } from 'react-router-dom';
import AuthenticatedPage from '../../../routes/utils/AuthenticatedPage';

const useStyles = makeStyles({
  body: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: mainColors.background,
  },
  main: {
    height: 'calc(100% - 6rem)',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: '1rem',
  },
});

const RAIDRLayout = () => {
  const classes = useStyles();

  const controlBarIsShowing = useSelector(isControlBarShowingSelector);

  // Authenticated page semi working - need to figure the dashboard redirect and it matches the section/null
  return (
    <RaidrKidsProvider>
      <main className={classes.body}>
        <TopBar />
        {controlBarIsShowing && <ControlBar />}
        <main className={classes.main}>
          <div className={classes.content}>
            <AuthenticatedPage Component={Outlet} hardSection="raidr" />
          </div>
        </main>
        <RaptorSnackbar />
      </main>
    </RaidrKidsProvider>
  );
};
export default RAIDRLayout;
