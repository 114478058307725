import React, { FC } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Switch, Typography } from '@mui/material';
import { mainColors } from '../../../../../../styling/theme';
import { useRaidrKids } from '../../context/RaidrKidsContext';

interface SaveConfirmationDialogBoxProps {
  dialogIsShowing: boolean;
  setDialogIsShowing: (value: boolean) => void;
  getTemplateChangesMade: () => string[];
  getCanUserSave: () => string[];
  handleSaveChanges: (applyToAllDocuments: boolean) => void;
  figuresWereUpdated: boolean;
  handleUpdateFigures: (applyToAllShareClasses: boolean) => void;
  checkTemplateChanges: () => Promise<string[]>;
}

const useStyles = makeStyles(() => ({
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareClassesContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    overlowY: 'scroll',
    maxHeight: '20rem',
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SaveConfirmationDialogBox: FC<SaveConfirmationDialogBoxProps> = (
  props,
) => {
  // const classes = useStyles();
  const {
    dialogIsShowing,
    setDialogIsShowing,
    getTemplateChangesMade,
    getCanUserSave,
    handleSaveChanges,
    figuresWereUpdated,
    handleUpdateFigures,
    checkTemplateChanges,
  } = props;

  const { isPriips } = useRaidrKids();
  // Check if any template changes were made to the document
  const changesMade = getTemplateChangesMade();
  const classes = useStyles();
  // Used to indicate if template changes should be applied to all documentsq
  const [applyToAllDocuments, setApplyToAllDocuments] =
    React.useState<boolean>(true);
  // Used to indicate if figures should be updated in all share classes
  const [applyToAllShareClasses, setApplyToAllShareClasses] =
    React.useState<boolean>(true);
  // Generate any error messages that will prevent the user from saving the document
  const errorMessages = getCanUserSave();
  // Used to indicate if the affected share class by template changes should be displayed
  const [showAffectedShareClass, setShowAffectedShareClass] =
    React.useState<boolean>(false);
  // Variable for storing affected share classes
  const [affectedShareClasses, setAffectedShareClasses] = React.useState<
    string[]
  >([]);
  // Function for displaying the share classes affected by template changes
  const displayAffectedShareClasses = async () => {
    const shareClasses = await checkTemplateChanges();
    setAffectedShareClasses(shareClasses);
    setShowAffectedShareClass(true);
  };
  return (
    <Dialog
      open={dialogIsShowing}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setDialogIsShowing(false)}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle textAlign="center">Confirm save latest version?</DialogTitle>
      {showAffectedShareClass ? (
        <DialogContentText
          id="template-header-text"
          textAlign="center"
          style={{ fontSize: '1.2rem', fontWeight: 600 }}
        >
          The following share classes will be affected:
        </DialogContentText>
      ) : null}

      {changesMade.length > 0 ? (
        <DialogContent>
          {showAffectedShareClass ? (
            <>
              <div className={classes.shareClassesContainer}>
                {affectedShareClasses.sort().map((shareClass, index) => (
                  <div
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? mainColors.pastelBlue_lighter
                          : undefined,
                      width: '100%',
                    }}
                  >
                    <DialogContentText
                      key={index}
                      id={`share-class-${index}`}
                      textAlign="center"
                      style={{ fontSize: '1.2rem', fontWeight: 600 }}
                    >
                      {shareClass}
                    </DialogContentText>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <DialogContentText
                id="template-header-text"
                textAlign="center"
                style={{ fontSize: '1.2rem', fontWeight: 600 }}
              >
                Template Changes were made. Click below to view affected
                documents:
              </DialogContentText>
              <Box textAlign={'center'} style={{ marginTop: '1rem' }}>
                <Button
                  sx={{
                    backgroundColor: mainColors.mainBlue,
                    color: 'white',
                    '&:hover': {
                      backgroundColor: mainColors.mainBlue_lighter,
                    },
                  }}
                  onClick={displayAffectedShareClasses}
                >
                  See Affected Documents
                </Button>
              </Box>
            </>
          )}
        </DialogContent>
      ) : null}
      {figuresWereUpdated ? (
        <DialogContent>
          <DialogContentText id="figures-header-text" textAlign="center">
            The figures were updated in this document
          </DialogContentText>
          <DialogContentText id="template-subheader-text" textAlign="center">
            Would you like to update figures in all other share classes of this
            fund?
          </DialogContentText>
          <div className={classes.switch}>
            <Typography>Just This Share Class</Typography>
            <Switch
              checked={applyToAllShareClasses}
              onChange={() =>
                setApplyToAllShareClasses(!applyToAllShareClasses)
              }
            />
            <Typography>All Share Class</Typography>
          </div>
        </DialogContent>
      ) : null}
      {isPriips && errorMessages.length ? (
        <>
          <DialogContentText id="error-message-header-text" textAlign="center">
            <Typography style={{ fontWeight: 600, color: 'red' }}>
              The following errors must be resolved before saving:
            </Typography>
          </DialogContentText>
          {errorMessages.map((message, index) => (
            <DialogContentText
              key={index}
              id={`error-message-${index}`}
              textAlign="center"
            >
              {message}
            </DialogContentText>
          ))}
        </>
      ) : null}
      <DialogActions>
        <Button onClick={() => setDialogIsShowing(false)}>Cancel</Button>
        <Button
          onClick={() => {
            handleSaveChanges(applyToAllDocuments);
            if (figuresWereUpdated) {
              handleUpdateFigures(applyToAllShareClasses);
            }
            setDialogIsShowing(false);
          }}
          disabled={isPriips && errorMessages.length > 0}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveConfirmationDialogBox;
