import {
  LoaderFunctionArgs,
  RouteObject,
  useLoaderData,
  useParams,
} from 'react-router-dom';
import { FunctionComponent } from 'react';
import NursLaw from '../components/pages/nurs/law/NursLaw';
import QiaifLaw from '../components/pages/qiaif/law/QiaifLaw';
import Act40Law from '../components/pages/act40/law/Act40Law';
import FivgLaw from '../components/pages/fivg/law/FivgLaw';
import PartiiLaw from '../components/pages/partii/law/PartiiLaw';
import CssfRiskSpreading from '../components/pages/sif-raif/cssfRiskSpreading/CssfRiskSpreading.component';
import Counterparty from '../components/pages/ucits/counterparty/Counterparty';
import StressScenario from '../components/pages/ucits/stressTests/StressTestsTable';
import PeStressScenario from '../components/pages/privateEquity/peStressScenarioTests/PeStressScenarioTests.component.tsx';
import PeValueAtRisk from '../components/pages/privateEquity/peValueAtRisk/PeValueAtRisk.component';
import HistoricalData from '../components/pages/ucits/historical/component';
import RiskComparison from '../components/pages/ucits/riskComparison/component';
import VarBacktest from '../components/pages/ucits/varBacktest/component';
import SubFundSummary from '../components/pages/utilities/SubFundSummary/SubFundSummary.component';
import FailsPage from '../components/pages/utilities/Fails/FailsPage.component';
import HistoricalFailsPage from '../components/pages/utilities/HistoricalFails/HistoricalFailsPage.component';
import AifmdReports from '../components/pages/aifmd/reports/component';
import Equity from '../components/pages/asset_class/equity/Equity';
import SyntheticRisk from '../components/pages/ucits/synthetic-risk/SyntheticRisk';
import SyntheticRiskShareClass from '../components/pages/ucits/syntheticRiskShareClass/SyntheticRiskShareClass';
import ShareClassHedgeData from '../components/pages/reportsPages/shareClassHedge/ShareClassHedgeData.component';
import Currency from '../components/pages/asset_class/currency/Currency';
import FixedIncome from '../components/pages/asset_class/fixedIncome/FixedIncome';
import Cis from '../components/pages/asset_class/cis/Cis';
import FundAssetLiquidationTime from '../components/pages/liquidity/fundAssetLiquidationTime/component';
import LiquidityAdjustedSettlementDateAchievableBalance from '../components/pages/liquidity/lasdab/component';
import Moab from '../components/pages/liquidity/moab/component';
import RstFixed from '../components/pages/liquidity/rstFixed/component';
import AifRstFixed from '../components/pages/aif_liquidity/rst-fixed/rst-fixed.component';
import RstVariable from '../components/pages/liquidity/rstVariable/component';
import LiquidationRiskOverview from '../components/pages/liquidity/liquidationRiskOverview/component';
import CounterpartyMarketLiquidityRisk from '../components/pages/liquidity/counterparty/component';
import RstEvolution from '../components/pages/liquidity/RstEvolution/component';
import ESGCompliance from '../components/pages/esg/esgExposure/Esg.component';
import SubscriptionsRedemptions from '../components/pages/ucits/subscriptionsRedemptions/SubscriptionsRedemptions';
import DiscountCashflow from '../components/pages/privateEquity/discountCashFlow/DiscountCashflow.component';
import PeHeatMap from '../components/pages/privateEquity/peHeatMap/PeHeatMap.component';
import PeExposure from '../components/pages/privateEquity/peExposure/PeExposure.component';
import MarketLiquidityRisk from '../components/pages/liquidity/marketLiquidityRisk/MarketLiquidityRisk.component';
import VariableMarketVolumeLiquidationCosts from '../components/pages/liquidity/variableMarketVolumeLiquidationCosts/VariableMarketVolumeLiquidationCosts.component';
import PeLiquiidtyValueAtRisk from '../components/pages/privateEquity/peLiquidityValueAtRisk/PeLiquidityValueAtRisk.component';
import PeMarketRiskOverview from '../components/pages/privateEquity/marketRiskOverview/PeMarketRiskOverview.component';
import MonteCarloVar from '../components/pages/privateEquity/monteCarloVar/MonteCarloVar.componet';
import LiquidityDiscountAnalysis from '../components/pages/privateEquity/liquidityDiscountAnalysis/LiquidityDiscountAnalysis.component';
import DiscountCashflowConfiguration from '../components/pages/privateEquity/discountCashFlowConfiguration/DiscountCashFlowConfiguration.component';
import DiscountCashflowOverview from '../components/pages/privateEquity/discountCashflowOverview/DiscountCashflowOverview.component';
import PerformanceReport from '../components/pages/sharedComponents/performanceReport/PerformanceReport.component';
import CollateralMonitor from '../components/pages/ucits/collateralMonitor/CollateralMonitor.component';
import { Notes } from '@mui/icons-material';
import PerformanceDiagnostics from '../components/pages/performanceAttribution/performanceDiagnostics/PerformanceDiagnostics.component';
import SubFundPerformance from '../components/pages/performanceAttribution/subFundPerformance/SubFundPerformance.component.';
import AssetAllocationPerformance from '../components/pages/performanceAttribution/assetAllocationPerformance/AssetAllocationPerformance.component';
import AssetAndCurrency from '../components/pages/performanceAttribution/assetAndCurrency/AssetAndCurrency.component';
import SubFundCumulativeBreakdown from '../components/pages/performanceAttribution/subFundCumulativeBreakdown/SubFundCumulativeBreakdown.component';
import ExposureVsCumulativeReturns from '../components/pages/performanceAttribution/exposureVsCumulativeReturns/ExposureVsCumulativeReturns.component';
import SubFundHistorical from '../components/pages/performanceAttribution/subFundHistorical/SubFundHistorical.component';
import SubFundVsBenchmark from '../components/pages/performanceAttribution/subFundVsBenchmark/SubFundVsBenchmark.component';
import StockSelectionPerformance from '../components/pages/performanceAttribution/stockSelectionPerformance/StockSelectionPerformance.component';
import ESGPortfolioSummary from '../components/pages/esg/portfolioSummary/PortfolioSummary.component';
import ArticleScanner from '../components/pages/esg/articleScanner/ArticleScanner.component';
import PrincipleAdverseImpact from '../components/pages/esg/principleAdverseImpact/PrincipleAdverseImpact.component';
import PortfolioCarbonEmissions from '../components/pages/esg/portfolioCarbonEmissions/PortfolioCarbonEmissions.component';
import IndividualESGPage from '../components/pages/esg/individualESGPage/individualESGPage.component';
import ESGStressTests from '../components/pages/esg/esgStressTests/ESGStressTests.component';
import EsgVAR from '../components/pages/esg/esgVar/EsgVAR.component';
import IndicatorCompliance from '../components/pages/esg/indicatorCompliance/IndicatorCompliance.component';
import IndicatorCoverageRatioMonitor from '../components/pages/esg/IndicatorCoverageRatioMonitor/IndictaorCoverageRatioMonitor.component';
import EsAlignmentMonitor from '../components/pages/esg/esAlignmentMonitor/EsAlignmentMonitor.component';
import HistoricalDataExport from '../components/pages/manco/historicalDataExport/HistoricalDataExport.component';
import CounterpartyStressTests from '../components/pages/sharedComponents/counterpartyStressTests/CounterpartyStressTests.component';
import AlternativeNonCis from '../components/pages/asset_class/alternativeNonCis/AlternativeNonCis.component';
import PeProxyModel from '../components/pages/privateEquity/peProxyModel/PeProxyModel.component';
import RelativeRisk from '../components/pages/ucits/relativeRisk/RelativeRisk.component';
import CashflowStressTests from '../components/pages/privateEquity/cashFlowStressTests/CashFlowStressTests.component';
import FxRates from '../components/pages/utilities/fxRates/FxRates.component';
import PositionUpdatePortal from '../components/pages/manco/positionUpdatePortal/PositionUpdatePortal.page';
import { GeneralPageWrapper } from '../components/layout/general/GeneralPageWrapper';
import { GeneralFundInfoWrapper } from '../components/layout/general/GeneralFundInfoWrapper';
import { GeneralSelectAFundOverview } from '../components/pages/sharedComponents/GeneralUcitsOverview';
import RiaifLaw from '../components/pages/riaif/law/RiaifLaw';

const getFundIdLoader = async (data: LoaderFunctionArgs) => {
  const url = new URL(data.request.url); // Get the full URL of the request
  const fundId = url.searchParams.get('fundId'); // Check for the 'fundId' query parameter

  if (fundId) {
    return { fundId }; // Return the fundId if it exists
  }

  return null; // Pass the fundId to the route element via loader data
};

interface FallbackProps {
  pageId: string;
  Component: React.ComponentType<any>;
}

const Fallback: FunctionComponent<FallbackProps> = ({ pageId, Component }) => {
  const loaderData = useLoaderData() as any;
  const { section } = useParams();

  const fundId = loaderData?.fundId;

  if (!fundId) {
    return (
      <GeneralPageWrapper pageId={pageId}>
        <GeneralSelectAFundOverview pageId={pageId} />
      </GeneralPageWrapper>
    );
  }

  const renderedComponent =
    pageId === 'rst-fixed' && section === 'aifmd' ? AifRstFixed : Component;

  return (
    <GeneralPageWrapper pageId={pageId}>
      <GeneralFundInfoWrapper
        Component={renderedComponent}
        pageId={pageId}
        fundId={fundId}
      />
    </GeneralPageWrapper>
  );
};

export const FundRoutes = (data: any): RouteObject[] => [
  {
    path: '/:section/nurs-law',
    element: <Fallback pageId={'nurs-law'} Component={NursLaw} />,
    loader: (data) => getFundIdLoader(data),
  },

  {
    path: '/:section/qiaif-law',
    element: <Fallback pageId={'qiaif-law'} Component={QiaifLaw} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/riaif-law',
    element: <Fallback pageId={'riaif-law'} Component={RiaifLaw} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/act40-law',
    element: <Fallback pageId={'act40-law'} Component={Act40Law} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/fivg-law',
    element: <Fallback pageId={'fivg-law'} Component={FivgLaw} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/partii-law',
    element: <Fallback pageId={'partii-law'} Component={PartiiLaw} />,
    loader: (data) => getFundIdLoader(data),
  },

  {
    path: '/:section/notes',
    element: <Fallback pageId={'notes'} Component={Notes} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/cssf-risk-spreading',
    element: (
      <Fallback pageId={'cssf-risk-spreading'} Component={CssfRiskSpreading} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/counterparty',
    element: <Fallback pageId={'counterparty'} Component={Counterparty} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/stress-scenario-tests',
    element: (
      <Fallback pageId={'stress-scenario-tests'} Component={StressScenario} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/pe-stress-scenario-tests',
    element: (
      <Fallback
        pageId={'pe-stress-scenario-tests'}
        Component={PeStressScenario}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/pe-value-at-risk',
    element: <Fallback pageId={'pe-value-at-risk'} Component={PeValueAtRisk} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/historical-data',
    element: <Fallback pageId={'historical-data'} Component={HistoricalData} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/risk-comparison',
    element: <Fallback pageId={'risk-comparison'} Component={RiskComparison} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/var-backtest',
    element: <Fallback pageId={'var-backtest'} Component={VarBacktest} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/sub-fund-setup',
    element: <Fallback pageId={'sub-fund-setup'} Component={SubFundSummary} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/fails',
    element: <Fallback pageId={'fails'} Component={FailsPage} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/historical-fails',
    element: (
      <Fallback pageId={'historical-fails'} Component={HistoricalFailsPage} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/aifmd-reports',
    element: <Fallback pageId={'aifmd-reports'} Component={AifmdReports} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/equity',
    element: <Fallback pageId={'equity'} Component={Equity} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/srri',
    element: <Fallback pageId={'srri'} Component={SyntheticRisk} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/srri-share-class',
    element: (
      <Fallback
        pageId={'srri-share-class'}
        Component={SyntheticRiskShareClass}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/share-class-hedge',
    element: (
      <Fallback pageId={'share-class-hedge'} Component={ShareClassHedgeData} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/currency',
    element: <Fallback pageId={'currency'} Component={Currency} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/fixed-income',
    element: <Fallback pageId={'fixed-income'} Component={FixedIncome} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/cis',
    element: <Fallback pageId={'cis'} Component={Cis} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/fund-asset-liquidation-time',
    element: (
      <Fallback
        pageId={'fund-asset-liquidation-time'}
        Component={FundAssetLiquidationTime}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/lasdab',
    element: (
      <Fallback
        pageId={'lasdab'}
        Component={LiquidityAdjustedSettlementDateAchievableBalance}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/moab',
    element: <Fallback pageId={'moab'} Component={Moab} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/rst-fixed',
    element: <Fallback pageId="rst-fixed" Component={RstFixed} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/rst-variable',
    element: <Fallback pageId={'rst-variable'} Component={RstVariable} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/liquidation-risk-overview',
    element: (
      <Fallback
        pageId={'liquidation-risk-overview'}
        Component={LiquidationRiskOverview}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/counterparty-market-liquidity-risk',
    element: (
      <Fallback
        pageId={'counterparty-market-liquidity-risk'}
        Component={CounterpartyMarketLiquidityRisk}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/liquidity-risk-evolution',
    element: (
      <Fallback
        pageId={'liquidity-risk-evolution'}
        Component={LiquidationRiskOverview}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/reverse-stress-test-evolution',
    element: (
      <Fallback
        pageId={'reverse-stress-test-evolution'}
        Component={RstEvolution}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/esg',
    element: <Fallback pageId={'esg'} Component={ESGCompliance} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/subscriptions-redemptions',
    element: (
      <Fallback
        pageId={'subscriptions-redemptions'}
        Component={SubscriptionsRedemptions}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/discount-cashflow',
    element: (
      <Fallback pageId={'discount-cashflow'} Component={DiscountCashflow} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/pe-heat-map',
    element: <Fallback pageId={'pe-heat-map'} Component={PeHeatMap} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/pe-exposure',
    element: <Fallback pageId={'pe-exposure'} Component={PeExposure} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/market-liquidity-risk',
    element: (
      <Fallback
        pageId={'market-liquidity-risk'}
        Component={MarketLiquidityRisk}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/variable-market-volume-liquidation-costs',
    element: (
      <Fallback
        pageId={'variable-market-volume-liquidation-costs'}
        Component={VariableMarketVolumeLiquidationCosts}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/liquidity-pe-value-at-risk',
    element: (
      <Fallback
        pageId={'liquidity-pe-value-at-risk'}
        Component={PeLiquiidtyValueAtRisk}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/pe-market-risk-overview',
    element: (
      <Fallback
        pageId={'pe-market-risk-overview'}
        Component={PeMarketRiskOverview}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/pe-monte-carlo-var',
    element: (
      <Fallback pageId={'pe-monte-carlo-var'} Component={MonteCarloVar} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/pe-liquidity-discount-analysis',
    element: (
      <Fallback
        pageId={'pe-liquidity-discount-analysis'}
        Component={LiquidityDiscountAnalysis}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/discount-cashflow-configuration',
    element: (
      <Fallback
        pageId={'discount-cashflow-configuration'}
        Component={DiscountCashflowConfiguration}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/discount-cashflow-overview',
    element: (
      <Fallback
        pageId={'discount-cashflow-overview'}
        Component={DiscountCashflowOverview}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/performance-report',
    element: (
      <Fallback pageId={'performance-report'} Component={PerformanceReport} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/collateral-monitor',
    element: (
      <Fallback pageId={'collateral-monitor'} Component={CollateralMonitor} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/performance-diagnostics',
    element: (
      <Fallback
        pageId={'performance-diagnostics'}
        Component={PerformanceDiagnostics}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/sub-fund-performance',
    element: (
      <Fallback
        pageId={'sub-fund-performance'}
        Component={SubFundPerformance}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/asset-allocation-performance',
    element: (
      <Fallback
        pageId={'asset-allocation-performance'}
        Component={AssetAllocationPerformance}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/asset-and-currency',
    element: (
      <Fallback pageId={'asset-and-currency'} Component={AssetAndCurrency} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/sub-fund-cumulative-breakdown',
    element: (
      <Fallback
        pageId={'sub-fund-cumulative-breakdown'}
        Component={SubFundCumulativeBreakdown}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/initial-exposure-vs-cumulative-returns',
    element: (
      <Fallback
        pageId={'initial-exposure-vs-cumulative-returns'}
        Component={ExposureVsCumulativeReturns}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/sub-fund-historical-performance',
    element: (
      <Fallback
        pageId={'sub-fund-historical-performance'}
        Component={SubFundHistorical}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/sub-fund-vs-benchmark',
    element: (
      <Fallback
        pageId={'sub-fund-vs-benchmark'}
        Component={SubFundVsBenchmark}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/stock-selection-performance',
    element: (
      <Fallback
        pageId={'stock-selection-performance'}
        Component={StockSelectionPerformance}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/portfolio-esg',
    element: (
      <Fallback pageId={'portfolio-esg'} Component={ESGPortfolioSummary} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/article-scanner',
    element: <Fallback pageId={'article-scanner'} Component={ArticleScanner} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/principle-adverse-impact',
    element: (
      <Fallback
        pageId={'principle-adverse-impact'}
        Component={PrincipleAdverseImpact}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/carbon-emissions',
    element: (
      <Fallback
        pageId={'carbon-emissions'}
        Component={PortfolioCarbonEmissions}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/environmental-esg',
    element: (
      <Fallback pageId={'environmental-esg'} Component={IndividualESGPage} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/social-esg',
    element: <Fallback pageId={'social-esg'} Component={IndividualESGPage} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/corporate-governance-esg',
    element: (
      <Fallback
        pageId={'corporate-governance-esg'}
        Component={IndividualESGPage}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/esg-stress-tests',
    element: (
      <Fallback pageId={'esg-stress-tests'} Component={ESGStressTests} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/esg-var',
    element: <Fallback pageId={'esg-var'} Component={EsgVAR} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/indicator-compliance',
    element: (
      <Fallback
        pageId={'indicator-compliance'}
        Component={IndicatorCompliance}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/indicator-coverage-ratio-monitor',
    element: (
      <Fallback
        pageId={'indicator-coverage-ratio-monitor'}
        Component={IndicatorCoverageRatioMonitor}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/es-alignment-monitor',
    element: (
      <Fallback
        pageId={'es-alignment-monitor'}
        Component={EsAlignmentMonitor}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/historical-data-export',
    element: (
      <Fallback
        pageId={'historical-data-export'}
        Component={HistoricalDataExport}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/counterparty-stress-tests',
    element: (
      <Fallback
        pageId={'counterparty-stress-tests'}
        Component={CounterpartyStressTests}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/alternative-non-cis',
    element: (
      <Fallback pageId={'alternative-non-cis'} Component={AlternativeNonCis} />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/pe-proxy-model',
    element: <Fallback pageId={'pe-proxy-model'} Component={PeProxyModel} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/relative-risk',
    element: <Fallback pageId={'relative-risk'} Component={RelativeRisk} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/cashflow-stress-tests',
    element: (
      <Fallback
        pageId={'cashflow-stress-tests'}
        Component={CashflowStressTests}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/fx-rates',
    element: <Fallback pageId={'fx-rates'} Component={FxRates} />,
    loader: (data) => getFundIdLoader(data),
  },
  {
    path: '/:section/position-update-portal',
    element: (
      <Fallback
        pageId={'position-update-portal'}
        Component={PositionUpdatePortal}
      />
    ),
    loader: (data) => getFundIdLoader(data),
  },
];

export default FundRoutes;
