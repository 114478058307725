import ErrorEditorTile from '../components/pages/raidr/kids/editor/editorTiles/ErrorTile';

const withJSONParsing = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    const { priipsKidsElement } = props;

    try {
      JSON.parse(priipsKidsElement.content);
    } catch (e) {
      // Fire off error email with priipsKidsElement information

      // Return error tile
      return (
        <div
          key={`editorTile${priipsKidsElement.kiidIndex}_${priipsKidsElement.fieldId}`}
        >
          <ErrorEditorTile message="An error occurred while processing this content field. Please contact RiskSystem Support for assistance." />
        </div>
      );
    }

    return <WrappedComponent {...props} />;
  };
};

export default withJSONParsing;
