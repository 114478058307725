import { Controller, useForm } from 'react-hook-form';
import {
  convertToPdfVersion,
  priipsKidsDataPdfVersion,
} from './EditorPdfViewer.component';
import { BASE_URL } from '../../../../../../utilities/requestClient';
import { greys, mainColors } from '../../../../../../styling/theme';
import { Button, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { KidsData } from '../KidsEditor';

const useStyles = makeStyles(() => ({
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

const DownloadPdfForm = ({
  priipsKidsData,
}: {
  priipsKidsData: KidsData[];
}) => {
  const classes = useStyles();

  // Convert the data to the desired PDF version format
  const content: priipsKidsDataPdfVersion[] = priipsKidsData.map(
    (element: any) => convertToPdfVersion(element),
  );

  // Get the required data from the first element of the content array
  const { fund_id_string, fund_name, share_class, document_language, version } =
    content[0];

  // Initial state with the values we want to send
  const defaultValues = {
    contents: JSON.stringify(content),
    fund_id: fund_id_string,
    fund_name: fund_name,
    isin: share_class,
    document_language: document_language,
    params: JSON.stringify([
      'contents',
      'fund_id',
      'fund_name',
      'isin',
      'document_language',
    ]),
  };

  const { control } = useForm({
    defaultValues,
  });

  // The form action URL will be dynamically generated
  const actionUrl = `${BASE_URL}risksystem_pdf_generator/generate_new_kiid/${fund_name}_${share_class}_${version}.pdf`;

  return (
    <form action={actionUrl} method="POST">
      {/* Hidden inputs are handled by React Hook Form */}
      <Controller
        name="contents"
        control={control}
        render={({ field }) => <input {...field} type="hidden" />}
      />
      <Controller
        name="fund_id"
        control={control}
        render={({ field }) => <input {...field} type="hidden" />}
      />
      <Controller
        name="fund_name"
        control={control}
        render={({ field }) => <input {...field} type="hidden" />}
      />
      <Controller
        name="isin"
        control={control}
        render={({ field }) => <input {...field} type="hidden" />}
      />
      <Controller
        name="document_language"
        control={control}
        render={({ field }) => <input {...field} type="hidden" />}
      />
      <Controller
        name="params"
        control={control}
        render={({ field }) => <input {...field} type="hidden" />}
      />

      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title="Download PDF"
        placement="top"
        arrow
        enterNextDelay={2000}
        enterDelay={2000}
      >
        <Button
          variant="contained"
          type="submit"
          sx={{
            fontSize: '0.9rem',
            width: '13rem',
            height: '3rem',
            color: 'white',
            backgroundColor: mainColors.mainBlue,
            borderRadius: '0.2rem',
            padding: '0.2rem',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: mainColors.mainBlue_lighter,
            },
            fontWeight: 600,
            textTransform: 'none',
          }}
          disableElevation={true}
          endIcon={
            <DownloadForOfflineIcon
              sx={{
                padding: '0.2rem',
                fontSize: '3rem',
                width: '2.5rem',
                height: '2.5rem',
              }}
            />
          }
        >
          Download Document
        </Button>
      </Tooltip>
    </form>
  );
};

export default DownloadPdfForm;
