import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import { Dispatch, FC, useEffect, useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import client from '../../../../../utilities/requestClient';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import { KidsData } from '../../kids/editor/KidsEditor';
import { useRaidrKids } from '../../kids/context/RaidrKidsContext';

const useStyles = makeStyles(() => ({
  conrolContainer: {
    display: 'flex',
  },
  toggleIcon: {
    color: mainColors.mainBlue,
  },
  toggleIconMessage: {
    transform: 'translateY(8rem)',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

interface RefreshFiguresButtonProps {
  setPriipsKidsContent: Dispatch<any>;
  fundId: string;
  shareClass: string;
  documentLanguage: string | null;
  setForceRerender: Dispatch<boolean>;
}

const RefreshFiguresButton: FC<RefreshFiguresButtonProps> = (props) => {
  const {
    setPriipsKidsContent,
    fundId,
    shareClass,
    setForceRerender,
    documentLanguage,
  } = props;

  const { setFiguresUpdated } = useRaidrKids();
  const [kidFigures, setKidFigures] = useState<any>(null);
  // Create a variable for indicating if the current figures and published figures are the same
  // If this is the case we do not allow the user to refresh the figures.
  const [figuresMatch, setFiguresMatch] = useState<boolean>(false);

  const requestClient = client();

  // Create a function for pulling the latest figures for the current kid
  const requestKidFiguresData = async () => {
    requestClient
      .get(`get_kid_figures_data_by_share_class/${fundId}/${shareClass}`)
      .then((response) => {
        setKidFigures(response.data);
      });
  };

  // Pull the kids figures data on load
  useEffect(() => {
    requestKidFiguresData();
  }, []);
  // Function for searching through the current data and updating the relavent fields with the updated figures.
  // Currently this is a little messy as the data returned from the back end is not great.
  function updateFigures() {
    setPriipsKidsContent((allContent: KidsData[]) => {
      // Create a copy of the data
      const newContent = [...allContent];
      // First update the sri value
      // Find the srri_chart tag in the data and update the content
      let sri_index = -1;
      let performance_scenarios_index = -1;
      let costs_over_time_index = -1;
      let composition_of_costs_index = -1;
      for (let i = 0; i < newContent.length; i++) {
        if (
          sri_index !== -1 &&
          performance_scenarios_index !== -1 &&
          costs_over_time_index !== -1 &&
          composition_of_costs_index !== -1
        ) {
          break;
        }
        if (newContent[i].tag === 'srri_chart') {
          sri_index = i;
        } else if (newContent[i].tag === 'table_style4') {
          performance_scenarios_index = i;
        } else if (newContent[i].tag === 'table_style5') {
          costs_over_time_index = i;
        } else if (newContent[i].tag === 'table_style6') {
          composition_of_costs_index = i;
        }
      }
      // Update the content
      if (sri_index !== -1) {
        newContent[sri_index].content =
          kidFigures.current_data[0].SRI.toString();
      }
      if (performance_scenarios_index !== -1) {
        // Update the performance scenarios
        const new_peroformance_scenarios_data =
          kidFigures.current_data[0].scenarios;
        // Check if the holding period is 5 years
        if (kidFigures.current_data[0].holding_period === 5) {
          const performance_scenarios = [
            [
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[0][1][0][1],
              ),
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[1][1][0][1],
              ),
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[2][1][0][1],
              ),
            ],
            [
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[0][1][0][0],
              ),
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[1][1][0][0],
              ),
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[2][1][0][0],
              ),
            ],
            [
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[0][1][1][1],
              ),
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[1][1][1][1],
              ),
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[2][1][1][1],
              ),
            ],
            [
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[0][1][1][0],
              ),
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[1][1][1][0],
              ),
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[2][1][1][0],
              ),
            ],
            [
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[0][1][2][1],
              ),
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[1][1][2][1],
              ),
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[2][1][2][1],
              ),
            ],
            [
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[0][1][2][0],
              ),
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[1][1][2][0],
              ),
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[2][1][2][0],
              ),
            ],
            [
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[0][1][3][1],
              ),
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[1][1][3][1],
              ),
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[2][1][3][1],
              ),
            ],
            [
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[0][1][3][0],
              ),
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[1][1][3][0],
              ),
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[2][1][3][0],
              ),
            ],
          ];
          newContent[performance_scenarios_index].content = JSON.stringify(
            performance_scenarios,
          );
        } else {
          const performance_scenarios = [
            [
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[0][1][0][1],
              ),
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[2][1][0][1],
              ),
            ],
            [
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[0][1][0][0],
              ),
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[2][1][0][0],
              ),
            ],
            [
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[0][1][1][1],
              ),
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[2][1][1][1],
              ),
            ],
            [
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[0][1][1][0],
              ),
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[2][1][1][0],
              ),
            ],
            [
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[0][1][2][1],
              ),
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[2][1][2][1],
              ),
            ],
            [
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[0][1][2][0],
              ),
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[2][1][2][0],
              ),
            ],
            [
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[0][1][3][1],
              ),
              addCommasToNumbersAndRound(
                new_peroformance_scenarios_data[2][1][3][1],
              ),
            ],
            [
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[0][1][3][0],
              ),
              percentageToTwoDecimalPlaces(
                new_peroformance_scenarios_data[2][1][3][0],
              ),
            ],
          ];
          newContent[performance_scenarios_index].content = JSON.stringify(
            performance_scenarios,
          );
        }
      }
      // Update the costs over time
      if (costs_over_time_index !== -1) {
        const costs_over_time = [
          [
            addCommasToNumbersAndRound(
              kidFigures.current_data[0].riy_dict[1][1],
            ),
            addCommasToNumbersAndRound(
              kidFigures.current_data[0].riy_dict[
                kidFigures.current_data[0].holding_period
              ][1],
            ),
          ],
          [
            percentageToTwoDecimalPlaces(
              kidFigures.current_data[0].riy_dict[1][0],
            ),
            percentageToTwoDecimalPlaces(
              kidFigures.current_data[0].riy_dict[
                kidFigures.current_data[0].holding_period
              ][0],
            ),
          ],
        ];
        newContent[costs_over_time_index].content =
          JSON.stringify(costs_over_time);
      }
      // Update the composition of costs
      if (composition_of_costs_index !== -1) {
        // Get the current data
        [
          [
            '',
            'The impact of the costs you pay when entering your investment.',
          ],
          [
            '0%',
            'The impact of the costs of exiting your investment when it matures.',
          ],
          [
            '',
            'The impact of the costs of us buying and selling underlying investments for the product.',
          ],
          [
            '',
            'The impact of the costs that we take each year for managing your investments.',
          ],
          [
            '',
            'The impact of the performance fee. We take these from your investment if the product outperforms its high water mark, of (a) the highest previous net asset value per share of the series concerned at the end of a performance period; and (b) the initial issue price of the shares of that series.',
          ],
          [
            '',
            'The impact of carried interests. We do not charge carried interests to the Company.',
          ],
        ];
        const current_comp_of_costs = JSON.parse(
          newContent[composition_of_costs_index].content,
        );
        // Store the new data that will be used for update
        const new_comp_of_costs = kidFigures.current_data[0].costs;
        // Update the data
        current_comp_of_costs[0][0] = percentageToTwoDecimalPlaces(
          new_comp_of_costs[0],
        );
        current_comp_of_costs[1][0] = percentageToTwoDecimalPlaces(
          new_comp_of_costs[1],
        );
        current_comp_of_costs[2][0] = percentageToTwoDecimalPlaces(
          new_comp_of_costs[2],
        );
        current_comp_of_costs[3][0] = percentageToTwoDecimalPlaces(
          new_comp_of_costs[3],
        );
        current_comp_of_costs[4][0] = percentageToTwoDecimalPlaces(
          new_comp_of_costs[4],
        );
        newContent[composition_of_costs_index].content = JSON.stringify(
          current_comp_of_costs,
        );
      }
      return newContent;
    });

    setFiguresUpdated(true);
    setFiguresMatch(true);
    setForceRerender(true);
  }

  // Create a messgae for the tooltip to explain  its use or why it is disabled
  const [tooltipMessage, setTooltipMessage] = useState<string>(
    'Update figures within document',
  );

  useEffect(() => {
    if (kidFigures) {
      // If current and published are the same then indicate that the figures match
      if (
        !kidFigures.current_data.length ||
        !kidFigures.published_data.length
      ) {
        // We will us the figures match variable here to diasble the button
        setFiguresMatch(true);
        // Update the tooltip message
        setTooltipMessage('No figures available to update');
      } else if (
        kidFigures.current_data[0].calculation_date ===
        kidFigures.published_data[0].calculation_date
      ) {
        setFiguresMatch(true);
        // Update the tooltip message
        setTooltipMessage(
          'Latest Figures are already contained in the document',
        );
      }
    }
  }, [kidFigures]);

  const classes = useStyles();

  return (
    <div className={classes.conrolContainer}>
      <Tooltip
        classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        title={tooltipMessage}
        placement="top"
        arrow
        enterNextDelay={2000}
        enterDelay={2000}
      >
        <Button
          variant="contained"
          // onClick={() => {
          //   return;
          // }}
          sx={{
            fontSize: '1.5rem',
            width: '18rem',
            color: 'white',
            backgroundColor:
              figuresMatch || documentLanguage === 'Swiss English'
                ? greys.grey400
                : mainColors.mainBlue,
            borderRadius: '0.2rem',
            padding: '0.2rem',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor:
                figuresMatch || documentLanguage === 'Swiss English'
                  ? greys.grey400
                  : mainColors.mainBlue_lighter,
            },
            fontWeight: 600,
            textTransform: 'none',
          }}
          disableElevation={true}
          onClick={
            figuresMatch || documentLanguage === 'Swiss English'
              ? undefined
              : updateFigures
          }
          endIcon={
            <CachedIcon
              sx={{
                padding: '0.2rem',
                fontSize: '3rem',
                width: '3rem',
                height: '2.5rem',
                marginRight: '0.5rem',
              }}
            />
          }
        >
          Refresh Figures
        </Button>
      </Tooltip>
    </div>
  );
};

export default RefreshFiguresButton;
